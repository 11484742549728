<template>
  <v-dialog v-model="viewDialog" width="700" @click:outside="closeDialog"  @keydown.esc="closeDialog">
    <v-card class="add-user">
        <v-card-title class="text-h6 d-flex justify-center py-10 pb-0">
          Transfer Money
        </v-card-title>
        <v-card-actions class="d-flex justify-space-around py-10">
          <v-btn color="#45D0D2" depressed outlined rounded x-large @click="openAgentDialog"> TO AGENT </v-btn>
          <v-btn color="#45D0D2" depressed outlined rounded x-large @click="openPlayerDialog"> TO PLAYER </v-btn>
        </v-card-actions>
    </v-card>
    <TransferMoney :transferMoneyDialog="transfer_money_dialog" @closeDialog="closeAlertDialog"/>
    <TransferMoneyAgent :transferMoneyAgentDialog="transfer_money_agent_dialog" @closeDialog="closeAlertDialog"/>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import TransferMoney from "../dashboard/TransferMoney.vue";
import TransferMoneyAgent from "../dashboard/TransferMoneyAgent.vue";
export default {
  props: ["transferMoneyUserType"],
  data() {
    return {
      alert_dialog: false,
      transfer_money_dialog: false,
      transfer_money_agent_dialog: false,
    };
  },
  components: {
    TransferMoney,
    TransferMoneyAgent
  },
  computed: {
    ...mapState("partner", ["partnerprofile", "adduser"]),
    viewDialog() {
      return this.transferMoneyUserType;
    },
  },
  methods: {
    ...mapActions("partner", ["getPartnerProfileData", "postAddUser"]),
    openAgentDialog(){
      this.transfer_money_agent_dialog = true;
    },
    openPlayerDialog(){
      this.transfer_money_dialog = true;
    },
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    closeAlertDialog() {
      this.transfer_money_agent_dialog = false;
      this.transfer_money_dialog = false;
      this.alert_dialog = false;
    },
  },
  async mounted() {},
};
</script>
<style>
.add-user{
  background: #0D111B !important;
  border-radius: 20px !important;
}.add-user-input{
  border-radius: 5px;
  border: 0;
  background: #222F3A;
}
</style>