<template>
  <v-dialog
    v-model="transferMoneyAgentDialog"
    width="700"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card class="transfer-money">
      <div class="tw-text-right tw-pr-4 tw-pt-3">
        <v-icon @click="closeDialog" color="red">mdi-close</v-icon>
      </div>
      <v-card-title class="text-h6 d-flex justify-center">
        Transfer Money to Agent
      </v-card-title>
      <v-col>
        <v-tabs
          active-class="transfer"
          background-color="transparent"
          v-model="transfermoneytab"
          slider-color="#45d0d2bf"
          slider-size="5"
        >
          <v-row class="d-flex justify-space-around">
            <v-tab href="#deposit">
              <h6 class="tabs-text white--text">Deposit</h6>
            </v-tab>
            <v-tab href="#cashout">
              <h6 class="tabs-text white--text">Cashout</h6>
            </v-tab>
          </v-row>
        </v-tabs>
      </v-col>
      <v-tabs-items class="tabs-items" v-model="transfermoneytab">
      <v-tab-item :value="transfermoneytab">
        <Deposit
          :viewDialog="transfermoneytab"
          :transferMoneyAgentDialog="transferMoneyAgentDialog"
          @closeDialog="closeDialog"
          v-show="transfermoneytab === 'deposit'"
        />
        <Cashout
          :viewDialog="transfermoneytab"
          :transferMoneyAgentDialog="transferMoneyAgentDialog"
          @closeDialog="closeDialog"
          v-show="transfermoneytab === 'cashout'"
        />
      </v-tab-item>
    </v-tabs-items>

    </v-card>
  </v-dialog>
</template>

<script>
import Cashout from "../modal/transfermoney/SubAgentsCashout.vue";
import Deposit from "../modal/transfermoney/SubAgentsDeposit.vue";

export default {
  props: {
    transferMoneyAgentDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      transfermoneytab: "deposit", 
    };
  },
  components: {
    Cashout,
    Deposit,
  },
  methods: {
    closeDialog() {
      console.log("Emitting closeDialog with false.");
      this.$emit("closeDialog", false);
      this.transfermoneytab = "deposit";
    },
  },

};
</script>
