<template>
  <v-dialog
    v-model="viewDialog"
    width="700"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card class="add-user">
      <div class="tw-text-right tw-pr-4 tw-pt-3">
        <v-icon @click="closeDialog" color="red">mdi-close</v-icon>
      </div>
      <v-card-title class="text-h6 d-flex justify-center py-2 pb-0">
        Transfer Money
      </v-card-title>
      <div
        :class="`tw-p-5 tw-py-14 tw-grid ${
          partnerprofile && partnerprofile.type != 'agent'
            ? 'md:tw-grid-cols-2'
            : 'md:tw-grid-cols-1 md:tw-px-60'
        } tw-grid-cols-1 tw-gap-4`"
      >
        <v-btn
          class="px-5"
          color="#45D0D2"
          large
          v-if="partnerprofile && partnerprofile.type != 'agent'"
          depressed
          outlined
          rounded
          @click="openAgentDialog"
        >
          FROM/TO AGENT
        </v-btn>
        <v-btn
          class="px-5"
          color="#45D0D2"
          large
          depressed
          outlined
          rounded
          @click="openPlayerDialog"
        >
          FROM/TO PLAYER
        </v-btn>
      </div>
    </v-card>
    <TransferMoney
      :transferMoneyDialog="transfer_money_dialog"
      @closeDialog="closeAlertDialog"
    />
    <TransferMoneyAgent
      :transferMoneyAgentDialog="transfer_money_agent_dialog"
      @closeDialog="closeAlertDialog"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import TransferMoney from "./TransferMoney.vue";
import TransferMoneyAgent from "../../dashboard/modal/TransferMoneyAgent.vue";
export default {
  props: ["transferMoneyUserType"],
  data() {
    return {
      alert_dialog: false,
      transfer_money_dialog: false,
      transfer_money_agent_dialog: false,
    };
  },
  components: {
    TransferMoney,
    TransferMoneyAgent,
  },
  computed: {
    ...mapState("partner", ["partnerprofile", "adduser"]),
    viewDialog() {
      return this.transferMoneyUserType;
    },
  },
  methods: {
    ...mapActions("partner", ["getPartnerProfileData", "postAddUser"]),
    openAgentDialog() {
      this.transfer_money_agent_dialog = true;
    },
    openPlayerDialog() {
      this.transfer_money_dialog = true;
    },
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    closeAlertDialog() {
      this.transfer_money_agent_dialog = false;
      this.transfer_money_dialog = false;
      this.alert_dialog = false;
    },
  },
  async mounted() {},
};
</script>
<style>
.add-user {
  background: #0d111b !important;
  border-radius: 20px !important;
}
.add-user-input {
  border-radius: 5px;
  border: 0;
  background: #222f3a;
}
</style>
