import store from "../../store";


export default ((to, from, next) => {
    const state = store.state.slotegator
    if (to.name != 'Home' && !!state.bt_reader) {
        state.bt_reader.kill()
        // that.$router.go(that.$router.currentRoute);
    }
    next();
});