const mutations = {
  GET_PARTNER_LIST: (state, payload) => {
    state.partnerlist = payload;
  },
  CASHOUT_FROM_PARTNER: (state, payload) => {
    state.cashoutpartner = payload;
  },
  DEPOSIT_FROM_PARTNER: (state, payload) => {
    state.depositpartner = payload;
  },
  GET_DEPOSIT_PARTNER_TRANSACTIONS: (state, payload) => {
    state.depositpartnertransactions = payload;
  },
  GET_CASHOUT_PARTNER_TRANSACTIONS: (state, payload) => {
    state.cashoutpartnertransactions = payload;
  },
  SET_ALL_SUBPARTNER: (state, payload) => {
    state.subpartners = payload;
  },
  SET_PARTNER_PROFILE_DATA: (state, payload) => {
    state.partnerprofile = payload;
  },
  SET_PARTNER_DEPOSIT: (state, payload) => {
    state.partnerdeposit = payload;
  },
  SET_PARTNER_CASHOUT: (state, payload) => {
    state.partnercashout = payload;
  },
  SET_PARTNER_DIRECT_CASHOUT: (state, payload) => {
    state.partnerdirectcashout = payload;
  },
  SET_PARTNER_DIRECT_DEPOSIT: (state, payload) => {
    state.partnerdirectdeposit = payload;
  },
  SET_AGENT_TRANSACTION_DEPOSIT: (state, payload) => {
    state.agenttransactiondeposit = payload;
  },
  SET_AGENT_TRANSACTION_CASHOUT: (state, payload) => {
    state.agenttransactioncashout = payload;
  },
  SET_PARTNER_PAYOUT_HISTORY: (state, payload) => {
    state.partnerpayouthistory = payload;
  },
  SET_BUY_CREDITS: (state, payload) => {
    state.buycredits = payload;
  },
  SET_SELL_CREDITS: (state, payload) => {
    state.sellcredits = payload;
  },
  GET_DASHBOARD_TOTAL_BY_DATE: (state, payload) => {
    state.dashboardtotalbydate = payload;
  },
  GET_DASHBOARD_TOTAL_GRAPH_BY_DATE: (state, payload) =>{
    state.dashboardGraphTotal = payload;
  },
  SET_ADD_USER : (state, payload) => {
    state.adduser = payload;
  },
  GET_PARTNER_USER : (state, payload) => {
    state.partneruser = payload;
  },
  GET_PARTNER_SUB_USER: (state, payload) => {
    state.partnersubuser = payload;
  },
  SET_TRANSFER_TRANSACTION : (state, payload) => {
    state.transfertransaction = payload
  },
  GET_PARTNER_SUB_BUY_CREDITS: (state, payload) => {
    state.partnersubbuy = payload;
  },
  GET_PARTNER_SUB_SELL_CREDITS: (state, payload) => {
    state.partnersubsell = payload;
  },
  GET_AGENT_TREE: (state,payload) => {
    state.agenttree = payload
  },
  SET_AGENT_TRANSFER_TRANSACTION : (state, payload) => {
    state.agenttransfertransaction = payload
  },
  UPDATE_COMMISSION_PERCENTAGE : (state, payload) => {
    state.updatecommissionpercentage = payload
  },
  GET_AGENT_INFO : (state, payload) => {
    state.agentinfo = payload
  },
  GET_PARTNER_AGENT_LIST : (state, payload) => {
    state.partneragentlist = payload
  },
  GET_PARTNER_PENDING_PAYOUT: (state, payload) => {
    state.partnerpendingpayout = payload;
  },
  SET_PARTNER_TRANSACTIONS: (state,payload) => {
    state.partnertransactions = payload;
  },
  SET_ERROR: (state, payload) => {
    state.error = payload;
  },
  setSocketMutation: (state,payload) => {
    state.socket = payload;
  },
};

export default mutations;
