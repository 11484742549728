const actions = {
    async getCasinoGames({commit}, payload){
    try {
            commit("SET_CASINO_GAMES", []);
            const response = await this._vm.$gameApi.get(`/casino/games/`,{
                params: {
                    search: payload.search,
                    filterTypes:payload.filterTypes,
                    isMobile: payload.isMobile,
                    providers: payload.providers,
                    type : payload.type,
                    offset : payload.offset,
                    limit : payload.limit,
                    is_home : payload.is_home,
                }
            });
            const { data } = response;
            commit("SET_CASINO_GAMES", data);
        }catch(error){
            console.log('error', error);
            commit('SET_ERROR', error);
        }
    },
    async getCasinoGamesByUuid({commit}, payload){
      try{
          const response = await this._vm.$gameApi.post(`/admin/casino/games/get`,payload);
          const { data } = response;
          return data;
      }catch(error){
          console.log('error', error);
          commit('SET_ERROR', error);
      }
  },

    async getCasinoGameDetailsByUuid({commit}, payload){
      try{
          const response = await this._vm.$gameApi.post(`/admin/casino/game-details/get`,payload);
          const { data } = response;
          return data;
      }catch(error){
          console.log('error', error);
          commit('SET_ERROR', error);
      }
  },
    async gameInit({}, payload) {
        try {
          const gameInit = await this._vm.$gameApi.post("/casino/games/init", payload);
          if (gameInit.data && gameInit.data.success) return gameInit.data;
          return {
            success: false,
            message: gameInit.data && gameInit.data.message,
            redirect: gameInit.data && gameInit.data.message && !!gameInit.data.message.includes("400"),
          };
        } catch (err) {
            
        }
      },
    
      async gameInitDemo({}, payload) {
        try {
          const gameInit = await this._vm.$gameApi.post("/casino/games/init-demo",payload);
          if (gameInit.data && gameInit.data.success) return gameInit.data;
          return {
            success: false,
            redirect: gameInit.data && gameInit.data.message && !!gameInit.data.message.includes("400"),
          };
        } catch (err) {
          console.log("game init error ", err);
        }
      },

      async getAdminCasinoGames({ commit },payload){
        try {
          const response = await this._vm.$gameApi.get("/admin/casino/games",{
            params: {
              provider_type: 'Slotegator',
              page: payload || 1,
            }
          })
          const { data } = response;
          commit('SET_ADMIN_CASINO_GAMES_GET', data)
        } catch(err) {
          commit('SET_ERROR', error);
        }
      },
      async getAdminCasinoJiliGames({ commit },payload){
        try {
          const response = await this._vm.$gameApi.get("/admin/casino/games",{
            params: {
              provider_type: 'Jili',
              page: payload || 1,
            }
          })
          const { data } = response;
          commit('SET_ADMIN_CASINO_JILI_GAMES_GET', data)
        } catch(err) {
          commit('SET_ERROR', error);
        }
      },
      async saveGameToGameDetails({},payload){
        try {
          const response = await this._vm.$gameApi.post("/admin/casino/game-details",payload);
          if (response.data && response.data.success) return response.data;
          return {
            success: false,
            redirect: response.data && response.data.message && !!response.data.message.includes("400"),
          };
        } catch (err) {
          console.log("game init error ", err);
        }
      },
      async getGameDetails({commit},payload){
        try{
            const response = await this._vm.$gameApi.get('admin/casino/game-details',{
              params: {
                pageSize: payload.pageSize,
                page:payload.page,
                search: payload.search,
                provider: payload.provider,
                type: payload.type
              }
            });
            const { data } = response;
            commit("SET_CASINO_GAMES_DETAILS", data);
        }catch(error){
            console.log('error', error);
            commit('SET_ERROR', error);
        }
      },
      async saveGameByType({},payload){
        try {
          const response = await this._vm.$gameApi.post("/admin/casino/games", payload);
          if (response.data && response.data.success) return response.data;
          return {
            success: false,
            redirect: response.data && response.data.message && !!response.data.message.includes("400"),
          };
        } catch (err){
          console.log("game init error ", err);
        }
      },
      async removeGame({},payload){
        try {
          const response = await this._vm.$gameApi.delete("/admin/casino/games", {
            data : {
              uuid : payload.uuid,
            }
          });
          console.log("responseeeeeeeeeeeee",response)
          if (response.data && response.data.success) return response.data;
          return {
            success: false,
            response,
            redirect: response.data && response.data.message && !!response.data.message.includes("400"),
          };
        } catch (err){
          console.log("game init error ", err);
        }
      },
      async updateSortGames({},payload){
        try {
          console.log("SORT",payload)
          let data = payload && payload.map(data => {
            return {
              sort_order : data.sort_order,
              id : data.id,
              is_home : data.is_home,
              type : data.type,
            }
          });
          console.log("SORT data",data.id)

          const response = await this._vm.$gameApi.put("/admin/casino/games/sort",data[0]);
          console.log("responseeeeeeeeeeeee",response)
          if (response.data && response.data.success) return response.data;
          return {
            success: false,
            response,
            redirect: response.data && response.data.message && !!response.data.message.includes("400"),
          };
        } catch (err) {
          console.log("game init error ", err);
        }
      },
      async loadMoreSlotGames({commit}, payload){
        try{
            const response = await this._vm.$gameApi.get(`/casino/games/`,{
                params: {
                    search: payload.search,
                    filterTypes:payload.filterTypes,
                    isMobile: payload.isMobile,
                    offset : payload.offset,
                    limit : payload.limit,
                    providers : payload.providers,
                    type : payload.type,
                    is_home : payload.is_home
                }
            });
            const { data } = response;
            return data
        }catch(error){
            console.log('error', error);
            commit('SET_ERROR', error);
        }
      },
      async loadMoreLiveGames({commit}, payload){
        try{
            const response = await this._vm.$gameApi.get(`/casino/games/`,{
                params: {
                    search: payload.search,
                    filterTypes:payload.filterTypes,
                    isMobile: payload.isMobile,
                    offset : payload.offset,
                    limit : payload.limit,
                    providers : payload.providers,
                    type : payload.type,
                    is_home : payload.is_home
                }
            });
            const { data } = response;
            return data
        }catch(error){
            console.log('error', error);
            commit('SET_ERROR', error);
        }
      },
      async loadMoreHomeGames({commit}, payload){
        try{
            const response = await this._vm.$gameApi.get(`/casino/games/`,{
                params: {
                    search: payload.search,
                    filterTypes:payload.filterTypes,
                    isMobile: payload.isMobile,
                    offset : payload.offset,
                    limit : payload.limit,
                    providers : payload.providers,
                    type : payload.type,
                    is_home : payload.is_home
                }
            });
            const { data } = response;
            return data
        }catch(error){
            console.log('error', error);
            commit('SET_ERROR', error);
        }
      },
      async getAdminCasinoPragmaticGames({ commit },payload){
        try {
          const response = await this._vm.$gameApi.get("/admin/casino/games",{
            params: {
              provider_type: 'Pragmatic Play',
              page: payload || 1,
            }
          })
          const { data } = response;
          commit('SET_ADMIN_CASINO_PRAGMATIC_GAMES_GET', data)
        } catch(err) {
          commit('SET_ERROR', err);
        }
      },
      async getAdminCasinoOneApiGames({ commit },payload){
        try {
          console.log("PAYLOAD ONE API",payload)
          const response = await this._vm.$gameApi.get("/admin/casino/games",{
            params: {
              provider_type: 'OneApi',
              page: payload || 1,
            }
          })
          console.log("ONE API RESPONSE",response)
          const { data } = response;
          commit('SET_ADMIN_CASINO_ONE_API_GAMES_GET', data)
        } catch(err) {
          commit('SET_ERROR', err);
        }
      },
}
export default actions;