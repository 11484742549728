import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import btRenderer from "./middleware/btRenderer.js";
import Authenticated from "./middleware/authenticated.js";
import isLoggedIn from "./middleware/isLoggedIn.js";
import isAdmin from "./middleware/isAdmin.js";
import isAffiliate from "./middleware/isAffiliate"
import isPartner from "./middleware/isPartner.js";
import maintenance from "./middleware/maintenance.js";
import isSuperAdmin from "./middleware/isSuperAdmin.js";
import Store from "../store/index";
NProgress.configure({ easing: "ease-out", speed: 500, showSpinner: false });

Vue.use(VueRouter);
// const Landing = () => import("../views/Landing.vue");
const Home = () => import("../views/Home.vue");
const Match = () => import("../views/Match.vue");
const Profile = () => import("../views/Profile.vue");
const AdminDashboard = () => import("../views/admin/AdminDashboard.vue");
const Partner = () => import("../views/partner/PartnerDashboard.vue");
const Affiliate = () => import("../views/affiliate/AffiliateDashboard.vue");
const Casino = () => import("../views/casino/Index.vue");
const CasinoPlay = () => import("../views/casino/pages/Play.vue");
const CasinoPlayTest = () => import("../views/casino/pages/PlayTest.vue");
const CasinoAll = () => import("../views/casino/pages/Index.vue");
const CasinoLive = () => import("../views/casino/pages/CasinoLive.vue");
const CasinoSlots = () => import("../views/casino/pages/CasinoSlots.vue");
const CasinoAdmin = () => import("../views/casino/pages/CasinoAdmin.vue");

const PartnerProfile = () => import("../components/partner/Profile.vue");
const PartnerTransactionHistory = () => import("../components/partner/TransactionHistory.vue");
const PartnerPayoutHistory = () => import("../components/partner/PayoutHistory.vue");
const PartnerDashboard = () => import("../components/partner/Dashboard.vue");
const AgentTree = () => import("../components/partner/dashboard/AgentTree.vue");
const PartnerActivePlayers = () => import("../components/partner/dashboard/ActivePlayers.vue");
const PartnerActiveAgents = () => import("../components/partner/dashboard/ActiveAgents.vue");

const AffiliateActivePlayers = () => import("../components/affiliate/dashboard/ActivePlayers.vue")

const Faqs = () => import("../views/Faqs.vue");
const ResponsibleGamblingPolicy = () => import("../views/GamblingPolicy.vue");
const GeneralTermConditions = () => import("../views/GeneralTermConditions.vue");
const KYCPolicy = () => import("../views/KYCPolicy.vue");
const PrivacyPolicy = () => import("../views/PrivacyPolicy.vue");
const ResetPassword = () => import("../views/ResetPassword.vue");
const ReactiveAccount = () => import("../views/ReactivateAccount.vue");
const Messages = () => import("../views/Messages.vue")

const AffiliateDashboard = () => import("../components/affiliate/Dashboard.vue");

const SubUserTransactionHistory = () => import("../components/partner/sub-user/SubUserTransactionHistory.vue");
const SubUserActiveAgent = () => import("../components/partner/sub-user/dashboard/ActiveAgent.vue");
const SubUserActivePlayers = () => import("../components/partner/sub-user/dashboard/ActivePlayers.vue");
const SubUserDashboard = () => import("../components/partner/sub-user/dashboard/Total.vue");

const MaintenancePage = () => import("../components/Maintenance.vue");
const DashboardOGC = () => import("../components/affiliate/ogc/Dashboard.vue");

const impersonateLogin = () => import("../views/Impersonate.vue");

const SuperAdminAudit = () => import("../views/super-admin/Index.vue")

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      guest: true,
    },
    component: Home,
  },
  {
    path: "*",
    name: "Home",
    meta: {
      guest: true,
    },
    component: Home,
  },
  // {
  //   path: "/home",
  //   name: "home",
  //   meta: {
  //     guest: true,
  //   },
  //   component: Home,
  // },
  {
    path: "/match/:id",
    name: "match",
    component: Match,
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      auth: true,
    },
    component: Profile,
    props: true,
  },
  {
    path: "/admin",
    name: "AdminDashboard",
    meta: {
      admin: true,
    },
    component: AdminDashboard,
  },
  {
    path: "/casino",
    name: "CasinoHome",
    component: Casino,
  },
  {
    path: "/casino/games",
    name: "CasinoGames",
    component: CasinoAll,
  },
  {
    path: "/casino/live",
    name: "CasinoLive",
    component: CasinoLive,
  },
  {
    path: "/casino/slots",
    name: "CasinoSlots",
    component: CasinoSlots,
  },
  {
    path: "/casino/:gameType/:gameId/:type",
    name: "CasinoPlay",
    component: CasinoPlay,
  },
  {
    path: "/casino/:gameType/:gameId/:type/test",
    name: "CasinoPlayTest",
    component: CasinoPlayTest,
  },
  {
    path: "/casino/admin/casino-games",
    name: "CasinoAdmin",
    meta: {
      super_admin: true,
    },
    component: CasinoAdmin,
  },
  {
    path: "/partner/dashboard",
    name: "Partner",
    meta: {
      partner: true,
    },
    component: Partner,
    children: [
      {
        path: "/partner/dashboard",
        name: "PartnerDashboard",
        meta: {
          partner: true,
        },
        component: PartnerDashboard,
      },
      {
        path: "/partner/profile/",
        name: "PartnerProfile",
        meta: {
          partner: true,
        },
        component: PartnerProfile,
      },
      {
        path: "/partner/transaction-history/",
        name: "PartnerTransactionHistory",
        meta: {
          partner: true,
        },
        component: PartnerTransactionHistory,
      },
      {
        path: "/partner/payout-history/",
        name: "PartnerPayoutHistory",
        meta: {
          partner: true,
        },
        component: PartnerPayoutHistory,
      },
      {
        path: "/partner/sub-user/",
        name: "SubUserTransactionHistory",
        meta: {
          partner: true,
        },
        component: SubUserTransactionHistory,
      },
      {
        path: "/partner/sub-user/active-agents/",
        name: "SubUserActiveAgent",
        meta: {
          partner: true,
        },
        component: SubUserActiveAgent,
      },
      {
        path: "/partner/sub-user/active-players/",
        name: "SubUserActivePlayers",
        meta: {
          partner: true,
        },
        component: SubUserActivePlayers,
      },
      {
        path: "/partner/sub-user/dashboard/",
        name: "SubUserDashboard",
        meta: {
          partner: true,
        },
        component: SubUserDashboard,
      },
      {
        path: "/partner/agent-tree/",
        name: "AgentTree",
        meta: {
          partner: true,
        },
        component: AgentTree,
      },
      {
        path: "/partner/active-players/",
        name: "PartnerActivePlayers",
        meta: {
          partner: true,
        },
        component: PartnerActivePlayers,
      },
      {
        path: "/partner/active-agents/",
        name: "PartnerActiveAgents",
        meta: {
          partner: true,
        },
        component: PartnerActiveAgents,
      },
      
      {
        path: "/partner/dashboard-ogc/",
        name: "DashboardOGC",
        meta: {
          partner: true,
        },
        component: DashboardOGC,
      },
    ],
  },
  {
    path: "/super-admin/audit",
    name: "SuperAdminAudit",
    meta: {
      super_admin: true,
    },
    component: SuperAdminAudit,
  },
  {
    path: "/faqs",
    name: "FAQS",
    meta: {
      guest: true,
    },
    component: Faqs,
  },
  {
    path: "/responsible-gambling-policy",
    name: "ResponsibleGamblingPolicy",
    meta: {
      guest: true,
    },
    component: ResponsibleGamblingPolicy,
  },
  {
    path: "/terms-conditions",
    name: "GeneralTermConditions",
    meta: {
      guest: true,
    },
    component: GeneralTermConditions,
  },
  {
    path: "/kyc-policy",
    name: "KYCPolicy",
    meta: {
      guest: true,
    },
    component: KYCPolicy,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: {
      guest: true,
    },
    component: PrivacyPolicy,
  },
  {
    path: "/forgot-password",
    name: "ResetPassword",
    meta: {
      guest: true,
    },
    component: ResetPassword,
  },
  {
    path: "/affiliate",
    name: "Affiliate",
    component: Affiliate,
    children: [
      {
        path: "/affiliate",
        name: "AffiliateDashboard",
        component: AffiliateDashboard,
        meta: {
          affiliate: true,
        }
      },
      {
        path: "/affiliate/active-players",
        name: "AffiliateActivePlayers",
        component: AffiliateActivePlayers,
        meta: {
          affiliate: true,
        }
      },
    ],
  },

  {
    path: "/activate-account",
    name: "ReactiveAccount",
    meta: {
      guest: true,
    },
    component: ReactiveAccount,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: {
      guest: true,
    },
    component: MaintenancePage,
  },
  {
    path: "/imp/:id",
    name: "maintenance",
    meta: {
      guest: true,
    },
    component: impersonateLogin,
  },
  {
    path: "/messages",
    name: "messages",
    meta: {
      auth: true,
    },
    component: Messages,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(isLoggedIn);
router.beforeEach(isSuperAdmin);
router.beforeEach(maintenance);
router.beforeEach(Authenticated);
router.beforeEach(isAdmin);
router.beforeEach(isPartner);
router.beforeEach(isAffiliate);
router.beforeEach(btRenderer);

//progressbar each navigate
router.beforeResolve((to, from, next) => {
  NProgress.start();
  if(to.path == '/profile'){
    document.getElementsByClassName("fb_dialog_content").forEach(item=>{ item.style.display = '' })
  } else {
    document.getElementsByClassName("fb_dialog_content").forEach(item=>{ item.style.display = 'none' })
  }
  next();
});
router.afterEach((to, from) => {
  NProgress.done();
});
export default router;
